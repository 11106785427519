html {
  height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  font-family: Roboto, Helvetica Neue, sans-serif;
  margin: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c1c1c1;
}

input[disabled],
textarea[disabled],
select[disabled='disabled'] {
  -webkit-opacity: 1;
  color: rgba(0, 0, 0, 1);
  background: transparent;
}

input[type='checkbox'],
input[type='radio'] {
  -webkit-opacity: 0;
}

a:-webkit-any-link {
  text-decoration: inherit;
}
